import React from 'react';
import './CvSection.css';
import GmailImage from './media/gmail.svg';
import LinkedInImage from './media/linkedin.svg';

function CvSection() {
  return (
    <html lang="en">
      <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Software Developer Resume</title>
        <link rel="stylesheet" href="CvSection.css" />
      </head>
      <body>
        <section>
          <h1 className="main-heading">Luke Wood</h1>
          <h2 className="email-heading">Contact: lukewood.dev@gmail.com</h2>
          <div className="contact-icons">
            <a href="mailto:lukewood.dev@gmail.com">
              <img src={GmailImage} alt="Email" />
            </a>
            <a href="https://uk.linkedin.com/in/luke-wood-199407198">
              <img src={LinkedInImage} alt="LinkedIn" />
            </a>
          </div>
          <div id="root"></div>

          <h2>WORK EXPERIENCE</h2>
          <h3 style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ textAlign: 'left' }}>Siemens Digital Industries Software</span>
            <span style={{ textAlign: 'right' }}>London, UK</span>
          </h3>
  
          <h4>Java Software Developer Jan 2022 – Aug 2023</h4>
          <ul>
            <li>Developed and maintained industry software written in Java for advanced modelling of chemical processes</li>
            <li>Expanded the functionality of a Java-based global system analysis tool by integrating constraint sets of initial factors into the generated data and graphs.</li>
            <li>Collaborated with external UX team to revamp application’s UI, also creating a centralised place for colour schemes to ease any future changes.</li>
            <li>Upgraded build tools for Java 11 to 17 migration of main application, including Gradle, Groovy, and custom JRE artifacts hosted on Nexus repository.</li>
            <li>Reviewer of an exporter for projects created in a Desktop Application so it can be used in a Web Applications Platform.</li>
          </ul>

          <h4>Java Software Developer Internship Aug 2021 – Jan 2022</h4>
          <ul>
            <li>Led migration of the main desktop application from Java 11 to 17, addressing unauthorized access and deprecated elements.</li>
            <li>Upgraded dependencies such as JUnit and Mockito, and automated changes using Python scripts.</li>
            <li>Developed a language expression factory using ANTLR 4 and Scala, facilitating the removal of ANTLR 2 from the codebase.</li>
          </ul>

          <h2>EDUCATION</h2>
          <p>
            <strong>BEng. Honours Computer Science</strong>
            <br />
            University of Edinburgh, May 2021
            <br />
            First Class Honours
            <br />
            GPA 4.0
          </p>
          <p>
            <strong>Scottish Highers</strong>
            <br />
            Kemnay Academy, May 2017
            <br />
            AAAAB
          </p>

          <h2>PROJECTS</h2>
            
            <h4>
              Personal Website
              <span className="italic-text"> <span class="separator">|</span> AWS, React, JavaScript, DynamoDB, HTTP API, Python</span>
            </h4>
            <ul>
              <li>Developed a website to display resume with interactive Fruit Ninja game using React</li>
              <li>Deployed as a static website on Amazon S3, ensuring HTTPS security with CloudFront, and configured custom DNS domain names for accessibility.</li>
              <li>Implemented the number of page visits using Amazon DynamoDB, created APIs with AWS API Gateway and Lambda, utilising Python for backend functionality.</li>
            </ul>

            <h4>
              C Compiler
              <span className="italic-text"> <span class="separator"> | </span> Java, MIPS, C</span>
            </h4>
            <ul>
                <li>Java-based compiler that translates C code into its assembly language equivalent</li>
                <li>Involved lexing, parsing, and building abstract syntax trees. Performing name and type analysis, then generating the corresponding MIPS code.</li>
            </ul>

            <h4>
                Web Scraper and Cost Analysis Tool for Grocery Items and Recipes
                <span className="italic-text"> <span class="separator">|</span> PostgreSQL, Python, Beautiful Soup</span>
            </h4>
            <ul>
                <li>Created a web scraper with BeautifulSoup to extract grocery item and recipe data</li>
                <li>Used PostgreSQL databases for data storage and Python for SQL queries</li>
                <li>Developed a cost-analysis tool to determine the most economical ways of purchasing recipe ingredients</li>
            </ul>

            <h4>
                Physical Memory Buddy Allocator for a Custom OS
                <span className="italic-text"> <span class="separator">|</span> C++, OS</span>
            </h4>
            <ul>
                <li>Designed and implemented a Physical Memory Buddy Allocator in C++ for a custom operating system</li>
                <li>Utilized a binary tree structure to manage and allocate pages in physical memory through a technique called buddy memory allocation</li>
            </ul>

          <h2>TECHNICAL SKILLS</h2>
          <p>Languages: Java, Python, C, SQL (Postgres), JavaScript, HTML/CSS, MIPS</p>
          <p>Frameworks: React, Swing, Gradle, JUnit, Mockito</p>
          <p>Developer Tools: AWS, Git, Gerrit, Jenkins, JIRA, Nexus</p>


          <h2>INTERESTS</h2>
          <p>
            Passionate about travelling and experiencing new things. Enjoy activities such as rock climbing, running and watching football. Socialising with friends also plays an important role in my life.
          </p>
        </section>
      </body>
    </html>
  );
}

export default CvSection;
