import React from 'react';
import './GameOverScreen.css';

const GameOverScreen = ({ score, resetGame, endGame }) => {
  return (
    <div className="game-over-screen">
      <h2 className="game-over-title">GAME OVER!</h2>
      <p className="score">Your Score: {score}</p>
      <div className="button-container">
        <button className="button" onClick={resetGame}>Play Again</button>
        <button className="button" onClick={endGame}>Exit Game</button>
      </div>
    </div>
  );
};

export default GameOverScreen;