import React, { useEffect, useState } from 'react';
import './Fruit.css';

const Fruit = ({ id, type, horizontalSpeed, onSlice, omega, setScore, gameOver, setGameOver }) => {
  const [position, setPosition] = useState({
    x: (Math.random() * 40) + 25,
    y: Math.random() * 15,
  });
  const [rotation, setRotation] = useState(0);
  const [isSliced, setIsSliced] = useState(false);
  const [vy, setDeltaY] = useState(-1);
  const acc = 0.05

  useEffect(() => {

    const fallInterval = setInterval(() => {
      const x = position.x + horizontalSpeed;
      const y = position.y + vy

      const rotationAngle = rotation + omega

      setPosition({ x, y });
      setRotation(rotationAngle);
      setDeltaY(vy + acc)

      if (position.y > 100) {
        clearInterval(fallInterval);
        if (!isSliced) {
          setGameOver(true);
          onSlice(id)
          setIsSliced(true)
        }
      }
    }, 30);

    return () => clearInterval(fallInterval);
  }, [position, isSliced, id, onSlice, horizontalSpeed]);

  const handleClick = () => {
    if (!isSliced) {
      setIsSliced(true);
      onSlice(id);
    }
    if (!gameOver) {
      setScore((prevScore) => prevScore + 1);
    }
  };

  return (
    <div
      className={`fruit ${isSliced ? 'sliced' : ''}`}
      style={{ top: `${position.y}%`, left: `${position.x}%`, transform: `rotate(${rotation}deg)` }}
      onMouseEnter={handleClick}
    >
      {type}
    </div>
  );
};

export default Fruit;

