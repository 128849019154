import React from 'react';
import './Header.css';

const Header = ({ onStartGame, inPlay, score, highScore }) => {
  return (
    <header className="header">
      <div className="header-content">
        <div className="start-button-wrapper">
          <button className="start-button" onClick={onStartGame}>Start Game</button>
        </div>
        {inPlay && (
          <div className="score-wrapper">
            <p className="score">Score: {score}</p>
          </div>
        )}
        <div className="highscore-wrapper">
          <p className="highscore">Highscore: {highScore}</p>
        </div>
      </div>
    </header>
  );
};

export default Header;
