import React from 'react';
import { DisplayVisitsNumber } from './Api';
import CvSection from './CvSection';
import Game from './game/Game';

function Home() {
    return (
        <div>
            <DisplayVisitsNumber />
            <CvSection />
            <Game />
        </div>
    )
}

export default Home