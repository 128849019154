import { useEffect, useState,  } from 'react';

function createApiEndpointWithParams(queryParams) {
  const apiEndpoint = 'https://1e02ks4fdl.execute-api.eu-west-2.amazonaws.com/default/lukewood_ninja-visitor_counter';
  const queryString = Object.keys(queryParams)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
    .join('&');

    return `${apiEndpoint}?${queryString}`;
}

function DisplayVisitsNumber() {
  const [visitorsCount, setVisitorsCount] = useState(null);
  const queryParams = {
    Item : 'visitors_count'
  };
  const apiEndpointWithParams = createApiEndpointWithParams(queryParams);

  useEffect(() => {
    fetch(apiEndpointWithParams, {
      method: 'GET'
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setVisitorsCount(data)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  useEffect(() => {
    if (visitorsCount !== null) {
      alert(`Hello, welcome to my CV. This website has been loaded ${visitorsCount} times.`);
    }
  }, [visitorsCount]);

  return null;
}

const fetchHighScore = async () => {
  const queryParams = {
    Item: 'highscore'
  };
  const apiEndpointWithParams = createApiEndpointWithParams(queryParams);

  try {
    const response = await fetch(apiEndpointWithParams, {
      method: 'GET'
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

function updateDBHighScore(newHighScore) {
  const queryParams = {
    Item : 'highscore',
    value : newHighScore
  };
  const apiEndpointWithParams = createApiEndpointWithParams(queryParams);

  try {
    const response = fetch(apiEndpointWithParams, {
      method: 'PUT'
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    console.log('High score updated successfully');
  } catch (error) {
    console.error('Error updating high score:', error);
  }
}

export { DisplayVisitsNumber, fetchHighScore, updateDBHighScore };

