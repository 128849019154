import React, { useEffect, useState } from 'react';
import { fetchHighScore, updateDBHighScore } from '../Api';
import Header from '../Header';
import Fruit from './Fruit';
import './Game.css';
import GameOverScreen from './GameOverScreen';

const Game = () => {
  const [inPlay, setInPlay] = useState(false);
  const [gameOver, setGameIsOver] = useState(false);
  const [score, setScore] = useState(0);
  const [highscore, setHighScore] = useState('loading...');
  const [fruits, setFruits] = useState([]);
  const [genSpeed, setGenSpeed] = useState(1000);
  const [tabInFocus, setTabInFocus] = useState(true);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setTabInFocus(!document.hidden);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (inPlay && tabInFocus) {
      const fruitInterval = setInterval(() => {
        setFruits((prevFruits) => [
          ...prevFruits,
          {
            id: Date.now(),
            type: getRandomFruitType(),
            horizontalSpeed: getRandomSpeed(),
            omega: getOmega(),
          },
        ]);
      }, genSpeed);

      return () => clearInterval(fruitInterval);
    }
  }, [genSpeed, inPlay, tabInFocus]);

  useEffect(() => {
    if (score > 0 && score % 5 === 0) {
      setGenSpeed((prevSpeed) => Math.max(prevSpeed - 100, 300));
    }
  }, [score]);

  useEffect(() => {
    fetchHighScore()
      .then(data => {
        setHighScore(data);
      })
      .catch(error => {
        console.error('Error fetching high score:', error);
      });
  }, []);

  const startGame = () => {
    resetGame();
    setInPlay(true);
  }

  const resetGame = () => {
    setFruits([]);
    setScore(0);
    setGenSpeed(1000);
    setGameIsOver(false);
  };

  const endGame = () => {
    setInPlay(false);
    resetGame(false);
  };

  const setGameOver = () => {
    if (score > highscore) {
      setHighScore(score);
      updateDBHighScore(score);
    }
    setGameIsOver(true);
  };

  const getRandomFruitType = () => {
    const fruitTypes = ['🍎', '🍊', '🍇', '🍌'];
    return fruitTypes[Math.floor(Math.random() * fruitTypes.length)];
  };

  const getRandomSpeed = () => {
    return Math.random() < 0.5 ? Math.random() * 0.2 + 0.2 : Math.random() * -0.2 - 0.2;
  };

  const getOmega = () => {
    return Math.random() * 10 * (Math.random() > 0.5 ? 1 : -1);
  };

  const handleSlice = (fruitId) => {
    setFruits((prevFruits) =>
      prevFruits.filter((fruit) => fruit.id !== fruitId)
    );
  };

  return (
    <div className="Game">
      <Header onStartGame={startGame} inPlay={inPlay} score={score} highScore={highscore} />
      {inPlay && (
        <div className="game-container">
          {fruits.map((fruit) => (
            <Fruit
              key={fruit.id}
              id={fruit.id}
              type={fruit.type}
              onSlice={handleSlice}
              horizontalSpeed={fruit.horizontalSpeed}
              omega={fruit.omega}
              setScore={setScore}
              gameOver={gameOver}
              setGameOver={setGameOver}
            />
          ))}
          {/* <TrailingCursor /> */}
        </div>
      )}
      {gameOver && (<GameOverScreen score={score} resetGame={resetGame} endGame={endGame} />)}
    </div>
  );
};

export default Game;
